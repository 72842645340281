// Gatsby supports TypeScript natively!
import { graphql } from "gatsby"
import React from "react"
import "../assets/sass/main.scss"
import BlogList from "../components/bloglist"
import Layout from "../components/layout"
import SEO from "../components/seo"


const BlogListTemplate = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = pageContext.posts;

  return (
    <Layout titleColor={null} location={location} title={siteTitle} previous={null} next={null} blogList>
      <SEO title={pageContext.filter} />
      <BlogList posts={posts} />
    </Layout>
  )
}

export default BlogListTemplate

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            cat
            cover_image {
              childImageSharp {
                fluid(maxWidth: 2560) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
` 