import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Img from "gatsby-image";

import "../assets/sass/main.scss"

const BlogList = ({ posts }) => {
    return (
        <div className="blogList">
            {posts &&
                posts.map(({ node }) => {
                    let desc = node.frontmatter.description;
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                        <article className="list-item" key={node.fields.slug}>
                            <div className="container">
                                <AniLink fade duration={0.3} to={node.fields.slug}>
                                    <div className="content">
                                        <div className="highlight">
                                            <header>
                                                <h3 className="blog-title">
                                                    {title}
                                                </h3>
                                                <small>{node.frontmatter.date} | {node.frontmatter.cat}</small>
                                            </header>
                                            <section
                                                className="description"
                                                dangerouslySetInnerHTML={{
                                                    __html: node.frontmatter.description || node.excerpt,
                                                }}
                                            />
                                            <picture className="featured-image">
                                                {node.frontmatter.cover_image &&
                                                    <Img style={{ width: '100%', height: '100%' }} fluid={node.frontmatter.cover_image.childImageSharp.fluid} />
                                                }
                                            </picture>
                                        </div>
                                    </div>
                                </AniLink>
                            </div>
                        </article>
                    )
                })
            }
        </div>
    )
}

export default BlogList;